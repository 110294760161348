import React from 'react';
import PropTypes from 'prop-types';

import { Section, Row, Column } from '../section/index';
import privateData from '../../../data.json';

import PhoneIcon from '../../assets/img/phone.svg';
import MailIcon from '../../assets/img/mail.svg';
import PlaceIcon from '../../assets/img/place.svg';

const Contacts = ({ title, subtitle, dark }) => (
  <Section title={title} centered dark={dark}>
    <Row centered>{subtitle}</Row>
    <Row list centered columnsPerRow={3} className="contacts-fix">
      <Column
        centered
        image={MailIcon}
        link={{
          to: privateData.organization.mailLink,
          text: privateData.organization.mailAddress
        }}
      />
      <Column
        centered
        image={PhoneIcon}
        link={{
          to: privateData.organization.phoneLink,
          text: privateData.organization.phoneNumber
        }}
      />
      <Column
        centered
        image={PlaceIcon}
        link={{
          to: privateData.utils.googleMapURL,
          text: `${privateData.organization.address} ${privateData.organization.city} ${privateData.organization.postalCode} ${privateData.organization.province}`,
          target: `_blank`,
          rel: `noreferrer noopener external nofollow`
        }}
      />
    </Row>
  </Section>
);

Contacts.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  dark: PropTypes.bool
};

export default Contacts;
