import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import Link from "../link";

class Column extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    image: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    className: PropTypes.string,
    centered: PropTypes.bool,
    small: PropTypes.bool,
    list: PropTypes.bool,
    link: PropTypes.shape({
      to: PropTypes.string,
      text: PropTypes.string,
      target: PropTypes.string,
      rel: PropTypes.string
    })
  };

  static defaultProps = {
    subtitle: null,
    centered: false,
    small: false,
    list: false
  };

  getImage() {
    if (typeof this.props.image !== `string`) {
      return this.props.image;
    }

    return this.props.image ? <img src={this.props.image} /> : null;
  }

  render() {
    const { title, subtitle, image, link, list } = this.props;

    if (list) {
      return (
        <li
          className={classnames(this.props.className, `column-item`, {
            centered: this.props.centered,
            small: this.props.small
          })}
        >
          {this.getImage()}
          <div
            className={classnames(`meta`, {
              small: this.props.small,
              centered: this.props.centered
            })}
          >
            {title ? <h2 className="title-subsection">{title}</h2> : null}
            {subtitle ? (
              <h3 className="subtitle-subsection">{subtitle}</h3>
            ) : null}
            {React.Children.count(this.props.children) ? (
              <p>{this.props.children}</p>
            ) : null}
            {link ? <Link {...link}>{link.text}</Link> : null}
          </div>
        </li>
      );
    } else {
      return (
        <div
          className={classnames(this.props.className, `column-item`, {
            centered: this.props.centered, // BUG: Check if this works
            small: this.props.small
          })}
        >
          {this.getImage()}
          <div
            className={classnames(`meta`, {
              small: this.props.small,
              centered: this.props.centered
            })}
          >
            {title ? <h2 className="title-subsection">{title}</h2> : null}
            {subtitle ? (
              <h3 className="subtitle-subsection">{subtitle}</h3>
            ) : null}
            {React.Children.count(this.props.children) ? (
              <p>{this.props.children}</p>
            ) : null}
            {link ? <a href={link.to}>{link.text}</a> : null}
          </div>
        </div>
      );
    }
  }
}

export default Column;
