import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import Column from "./column";
import { ExpandableList } from "../expandable-list";

class Row extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    centered: PropTypes.bool,
    className: PropTypes.string,
    spaceBelow: PropTypes.bool,
    spaceBelowValue: PropTypes.string,
    columnsPerRow: PropTypes.number,
    small: PropTypes.bool,
    list: PropTypes.bool
  };

  static defaultProps = {
    centered: false,
    spaceBelow: false,
    columnsPerRow: 2,
    spaceBelowValue: `3em`,
    small: false,
    list: false
  };

  getGridClass() {
    return `grid-` + this.props.columnsPerRow;
  }

  render() {
    const { children, list } = this.props;

    const childrenWithProps = React.Children.map(children, child => {
      if (child.type === Column || child.type === ExpandableList) {
        return React.cloneElement(child, {
          className: this.getGridClass(),
          list: list
        });
      }

      if (typeof child === `string`) {
        return <p>{child}</p>;
      }

      return child;
    });

    if (list) {
      return (
        <ul
          className={classnames(this.props.className, `row-wrapper`, {
            centered: this.props.centered,
            small: this.props.small
          })}
        >
          {this.props.title ? (
            <h2 className="subtitle-section">{this.props.title}</h2>
          ) : null}
          {childrenWithProps}
        </ul>
      );
    } else {
      return (
        <div
          className={classnames(this.props.className, `row-wrapper`, {
            centered: this.props.centered,
            small: this.props.small
          })}
        >
          {this.props.title ? (
            <h2 className="subtitle-section">{this.props.title}</h2>
          ) : null}
          {childrenWithProps}
        </div>
      );
    }
  }
}

export default Row;
