import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";

function SEO({ children, language, title, description, structuredData }) {
  // Stop compilation if missing required props.
  if (!title || !description) {
    throw new Error(`SEO is missing required props!`);
  }

  return (
    <Helmet title={title}>
      {language ? <html lang={language} /> : null}
      {description ? <meta name="description" content={description} /> : null}
      {structuredData ? (
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      ) : null}
      {children}
    </Helmet>
  );
}

SEO.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  language: PropTypes.string,
  structuredData: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export default SEO;
