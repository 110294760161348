import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import Column from "./column";
import Chevron from "../chevron";

// class Row extends React.Component {
//   static propTypes = {
//     title: PropTypes.string,
//     centered: PropTypes.bool,
//     className: PropTypes.string,
//     spaceBelow: PropTypes.bool,
//     spaceBelowValue: PropTypes.string,
//     columnsPerRow: PropTypes.number,
//     small: PropTypes.bool
//   };

//   static defaultProps = {
//     centered: false,
//     spaceBelow: false,
//     columnsPerRow: 2,
//     spaceBelowValue: `3em`,
//     small: false
//   };

//   getGridClass() {
//     return `grid-` + this.props.columnsPerRow;
//   }

//   render() {
//     const { children } = this.props;

//     const childrenWithProps = React.Children.map(children, child => {
//       if (child.type === Column) {
//         return React.cloneElement(child, {
//           className: this.getGridClass()
//         });
//       }

//       if (typeof child === `string`) {
//         return <p>{child}</p>;
//       }

//       return child;
//     });

//     return (
//       <div
//         className={classnames(
//           this.props.className,
//           `row-wrapper`,
//           // `accordion__section`,
//           {
//             centered: this.props.centered,
//             small: this.props.small
//           }
//         )}
//       >
//         <button className="subtitle-section">{this.props.title}</button>
//         {childrenWithProps}
//       </div>
//     );
//   }
// }

function ExpandableRow(props) {
  const [setActive, setActiveState] = useState(``);
  const [setHeight, setHeightState] = useState(`0px`);
  const [setRotate, setRotateState] = useState(`accordion__icon`);

  const content = useRef(null);

  React.useEffect(() => {
    window.addEventListener(`resize`, updateActiveHeight);
    return () => window.removeEventListener(`resize`, updateActiveHeight);
  });

  const updateActiveHeight = () => {
    setHeightState(
      setActive === `accordion__active`
        ? `${content.current.scrollHeight}px`
        : `0px`
    );
  };

  function toggleAccordion() {
    setActiveState(setActive === `` ? `accordion__active` : ``);
    setHeightState(
      setActive === `accordion__active`
        ? `0px`
        : `${content.current.scrollHeight}px`
    );
    setRotateState(
      setActive === `accordion__active`
        ? `accordion__icon`
        : `accordion__icon rotate`
    );
  }

  function getGridClass() {
    return `grid-` + props.columnsPerRow;
  }

  const childrenWithProps = React.Children.map(props.children, child => {
    if (child.type === Column) {
      return React.cloneElement(child, {
        className: getGridClass()
      });
    }

    if (typeof child === `string`) {
      return <p>{child}</p>;
    }

    return child;
  });

  return (
    <div
      className={classnames(
        props.className,
        `row-wrapper`,
        `accordion__section`,
        {
          centered: props.centered,
          small: props.small
        }
      )}
    >
      <div className={`accordion ${setActive}`} onClick={toggleAccordion}>
        <div className="accordion__header">
          <p className="accordion__title">{props.title}</p>
          <Chevron className={`${setRotate}`} width={10} fill={`#777`} />
        </div>
      </div>
      <div
        ref={content}
        style={{ maxHeight: `${setHeight}` }}
        className="accordion__content"
      >
        {childrenWithProps}
      </div>
    </div>
  );
}

ExpandableRow.propTypes = {
  title: PropTypes.string,
  centered: PropTypes.bool,
  className: PropTypes.string,
  spaceBelow: PropTypes.bool,
  spaceBelowValue: PropTypes.string,
  columnsPerRow: PropTypes.number,
  small: PropTypes.bool
};

ExpandableRow.defaultProps = {
  centered: false,
  spaceBelow: false,
  columnsPerRow: 2,
  spaceBelowValue: `3em`,
  small: false
};

export default ExpandableRow;
