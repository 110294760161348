import React from "react";
import classnames from "classnames";

export default ({ dark }) => (
  <div
    className={classnames(`section-break-wrapper`, {
      dark: dark
    })}
  >
    <div className="break-line"></div>
  </div>
);
