import React from "react";
import PropTypes from "prop-types";

import { Link as GatsbyLink } from "../../../plugins/gatsby-plugin-intl";

const Link = ({ children, to, activeClassName, partiallyActive, ...other }) => {
  // This assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  const internal = /^\/(?!\/)/.test(to);
  // Use Gatsby Link for internal links, and <a> for others
  if (internal) {
    return (
      <GatsbyLink
        to={to}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        {...other}
      >
        {children}
      </GatsbyLink>
    );
  }
  return (
    <a href={to} {...other}>
      {children}
    </a>
  );
};

Link.propTypes = {
  to: PropTypes.string,
  activeClassName: PropTypes.string,
  partiallyActive: PropTypes.string
};

export default Link;
