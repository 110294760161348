export default {
  '@context': `https://schema.org`,
  '@graph': [
    {
      '@type': `LocalBusiness`,
      name: `Battistutta Microfusioni`,
      logo: {
        '@type': `ImageObject`,
        '@id': `https://battistuttamicrofusioni.com/#logo`,
        url: `https://battistuttamicrofusioni.com/logo/logo-128-20201128.png`,
        width: 128,
        height: 128,
        caption: `Battistutta Microfusioni`
      },
      image: {
        '@id': `https://battistuttamicrofusioni.com/#logo`
      },
      '@id': `https://battistuttamicrofusioni.com/#localbusiness`,
      url: `https://battistuttamicrofusioni.com`,
      telephone: `+390131924188`,
      address: {
        '@type': `PostalAddress`,
        streetAddress: `Via Cavour, 75`,
        addressLocality: `Valenza`,
        postalCode: `15048`,
        addressCountry: `IT`
      },
      geo: {
        '@type': `GeoCoordinates`,
        latitude: 45.0154351,
        longitude: 8.6472458
      },
      openingHoursSpecification: [
        {
          '@type': `OpeningHoursSpecification`,
          dayOfWeek: [`Monday`, `Tuesday`, `Wednesday`, `Thursday`, `Friday`],
          opens: `09:00`,
          closes: `12:30`
        },
        {
          '@type': `OpeningHoursSpecification`,
          dayOfWeek: [`Monday`, `Tuesday`, `Wednesday`, `Thursday`, `Friday`],
          opens: `14:30`,
          closes: `18:00`
        }
      ]
    }
  ]
};
