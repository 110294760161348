import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

function ExpandableList(props) {
  const [setActive, setActiveState] = useState(``);
  const [setHeight, setHeightState] = useState(`0px`);
  const [setRotate, setRotateState] = useState(`accordion__icon`);

  const content = useRef(null);

  useEffect(() => {
    window.addEventListener(`resize`, updateActiveHeight);
    return () => window.removeEventListener(`resize`, updateActiveHeight);
  });

  const updateActiveHeight = () => {
    setHeightState(
      setActive === `accordion__active`
        ? `${content.current.scrollHeight}px`
        : `0px`
    );
  };

  function toggleAccordion() {
    setActiveState(setActive === `` ? `accordion__active` : ``);
    setHeightState(
      setActive === `accordion__active`
        ? `0px`
        : `${content.current.scrollHeight}px`
    );
    setRotateState(
      setActive === `accordion__active`
        ? `accordion__icon`
        : `accordion__icon rotate`
    );
  }

  function getImage() {
    if (typeof props.image !== `string`) {
      return props.image;
    }

    return props.image ? (
      <div className="accordion__clickable">
        <img src={props.image} onClick={toggleAccordion} />
        <h2 onClick={toggleAccordion}>{props.title}</h2>
        <p onClick={toggleAccordion}>Scopri i colori >></p>
      </div>
    ) : null;
  }

  return (
    <li
      className={classnames(props.className, `column-item`, {
        centered: props.centered, // BUG: Check if this works
        small: props.small || props.mini,
        mini: props.mini
      })}
    >
      {getImage()}
      <ul
        ref={content}
        style={{ maxHeight: `${setHeight}` }}
        className="accordion__content"
      >
        {React.Children.count(props.children) ? props.children : null}
        {/* {props.link ? <a href={props.link.to}>{props.link.text}</a> : null} */}
      </ul>
      {/* </div> */}
    </li>
  );
}

ExpandableList.propTypes = {
  title: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  centered: PropTypes.bool,
  small: PropTypes.bool,
  list: PropTypes.bool,
  link: PropTypes.shape({
    to: PropTypes.string,
    text: PropTypes.string,
    target: PropTypes.string,
    rel: PropTypes.string
  })
};

ExpandableList.defaultProps = {
  centered: false,
  small: false,
  list: false
};

export default ExpandableList;
