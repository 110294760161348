import React from 'react';
import PropTypes from 'prop-types';
import { graphql, StaticQuery } from 'gatsby';
import Link from 'gatsby-link';
import BackgroundImage from 'gatsby-background-image';

const Hero = ({ title, note, link, linkText, imagePath }) => (
  // As Gatsby static queries cannot use variables this is a workaround
  // https://github.com/gatsbyjs/gatsby/issues/10482#issuecomment-485349612
  <StaticQuery
    query={graphql`
      query {
        allFile(filter: { relativeDirectory: { eq: "images" } }) {
          nodes {
            relativePath
            childImageSharp {
              fluid(quality: 90, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    `}
    render={data => {
      // Static queries workaround
      const filteredImages = data.allFile.nodes.filter(img => {
        if (img.relativePath === imagePath) {
          return img.childImageSharp;
        }
        return null;
      });
      const image = filteredImages[0];

      if (!image) {
        throw new Error(`Cannot find ${imagePath}`);
      }
      // End of workaround

      return (
        <BackgroundImage className="hero" fluid={image.childImageSharp.fluid}>
          <div className="hero-wrapper">
            <h1 className="hero-title">{title}</h1>
            <div className="note">
              <p>{note}</p>
              <br />
              <Link to={link}>{linkText}</Link>
            </div>
          </div>
        </BackgroundImage>
      );
    }}
  />
);

Hero.propTypes = {
  title: PropTypes.string,
  note: PropTypes.string,
  link: PropTypes.string,
  linkText: PropTypes.string,
  imagePath: PropTypes.string
};

export default Hero;
