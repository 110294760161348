import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

class Section extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    centered: PropTypes.bool,
    dark: PropTypes.bool,
    className: PropTypes.string,
    semantic: PropTypes.bool
  };
  static defaultProps = {
    centered: false,
    dark: false,
    semantic: true
  };

  render() {
    if (this.props.semantic) {
      return (
        <section
          className={classnames(this.props.className, `section-wrapper`, {
            dark: this.props.dark,
            centered: this.props.centered
          })}
          id={this.props.id}
        >
          {this.props.title ? (
            <h1
              className={classnames(`title-section`, {
                centered: this.props.centered
              })}
            >
              {this.props.title}
            </h1>
          ) : null}
          {this.props.children}
        </section>
      );
    }

    return (
      <div
        className={classnames(this.props.className, `section-wrapper`, {
          dark: this.props.dark,
          centered: this.props.centered
        })}
        id={this.props.id}
      >
        {this.props.title ? (
          <h1
            className={classnames(`title-section`, {
              centered: this.props.centered
            })}
          >
            {this.props.title}
          </h1>
        ) : null}
        {this.props.children}
      </div>
    );
  }
}

export default Section;
