import React from 'react';
import PropTypes from 'prop-types';

import { injectIntl } from '../../plugins/gatsby-plugin-intl';
import {
  Section,
  Row,
  Column,
  SectionBreak,
} from '../components/section/index';

import {
  ExpandableList,
  ExpandableListItem,
} from '../components/expandable-list';
import SEO from '../components/seo';
import Hero from '../components/hero';
import Contacts from '../components/contacts';

import Au375 from '../assets/img/au375.svg';
import Au585 from '../assets/img/au585.svg';
import Au750 from '../assets/img/au750.svg';
import AgAlloys from '../assets/img/ag-alloys.svg';
import CuAlloys from '../assets/img/cu-alloys.svg';

import ClockIcon from '../assets/img/clock.svg';
import ChecklistIcon from '../assets/img/checklist.svg';
import QuoteIcon from '../assets/img/quote.svg';
import ShippingIcon from '../assets/img/shipping.svg';
import CastingIcon from '../assets/img/casting.svg';
import SoldersIcon from '../assets/img/solders.svg';

import structuredData from '../structured-data/home';

import privateData from '../../data.json';

const IndexPage = ({ intl: { formatMessage } }) => {
  return (
    <>
      <SEO
        title={formatMessage({ id: `title` })}
        description={formatMessage({ id: `description` })}
        structuredData={structuredData}
      >
        <meta
          name="google-site-verification"
          content={privateData.webmasterTools.key}
        />
      </SEO>

      <Hero
        title={formatMessage({ id: `hero.title` })}
        note={formatMessage({ id: `hero.note` })}
        link={formatMessage({ id: `hero.link` })}
        linkText={formatMessage({ id: `hero.link.text` })}
        imagePath={`images/hero-periodic-table-red-contrast.png`}
      />

      <main>
        <Section
          id="section-who"
          centered
          title={formatMessage({ id: `sec.opening.title` })}
        >
          <Row centered>{formatMessage({ id: `sec.opening.content` })}</Row>
        </Section>
        <SectionBreak />
        <Section
          title={formatMessage({ id: `sec.wwd.title` })}
          centered
          columnsPerRow={2}
        >
          <Row centered>
            <Column
              title={formatMessage({ id: `col.casting.title` })}
              image={CastingIcon}
            >
              {formatMessage({ id: `col.casting.content` })}
            </Column>

            <Column
              title={formatMessage({ id: `col.solders.title` })}
              image={SoldersIcon}
            >
              {formatMessage({ id: `col.solders.content` })}
            </Column>
          </Row>
        </Section>

        <SectionBreak />

        <Section title={formatMessage({ id: `sec.wwc.title` })}>
          <Row centered>{formatMessage({ id: `sec.wwc.content` })}</Row>
          <Row centered small columnsPerRow={3} list>
            <ExpandableList
              title={formatMessage({ id: `sec.wwc.au18` })}
              image={Au750}
              centered
              mini
            >
              <ExpandableListItem>{`Bianco - Ni Free (Pd6,25)`}</ExpandableListItem>
              <ExpandableListItem>{`Bianco - Ni Safe (Ni5)`}</ExpandableListItem>
              <ExpandableListItem>{`Bianco - Ni Safe (Ni3)`}</ExpandableListItem>
              <ExpandableListItem>{`Verde`}</ExpandableListItem>
              <ExpandableListItem>{`2N - Giallo chiaro`}</ExpandableListItem>
              <ExpandableListItem>{`3N - Giallo carico`}</ExpandableListItem>
              <ExpandableListItem>{`4N - Rosa`}</ExpandableListItem>
              <ExpandableListItem>{`5N - Rosso`}</ExpandableListItem>
              <ExpandableListItem>{`6N - Rosso carico`}</ExpandableListItem>
            </ExpandableList>
            <ExpandableList
              title={formatMessage({ id: `sec.wwc.au14` })}
              image={Au585}
              centered
              mini
            >
              <ExpandableListItem>{`Bianco`}</ExpandableListItem>
              <ExpandableListItem>{`Giallo`}</ExpandableListItem>
              <ExpandableListItem>{`Rosso`}</ExpandableListItem>
            </ExpandableList>
            <ExpandableList
              title={formatMessage({ id: `sec.wwc.au9` })}
              image={Au375}
              centered
              mini
            >
              <ExpandableListItem>{`Bianco - Ni/Pd Free`}</ExpandableListItem>
              <ExpandableListItem>{`Giallo`}</ExpandableListItem>
              <ExpandableListItem>{`Rosso`}</ExpandableListItem>
            </ExpandableList>
          </Row>
          <Row centered small list>
            <ExpandableList
              title={formatMessage({ id: `sec.wwc.ag` })}
              image={AgAlloys}
              centered
              mini
            >
              <ExpandableListItem>{`Ag 925`}</ExpandableListItem>
            </ExpandableList>
            <ExpandableList
              title={formatMessage({ id: `sec.wwc.cu` })}
              image={CuAlloys}
              centered
              mini
            >
              <ExpandableListItem>{`Ottone`}</ExpandableListItem>
              <ExpandableListItem>{`Bronzo`}</ExpandableListItem>
              <ExpandableListItem>{`Alpacca`}</ExpandableListItem>
            </ExpandableList>
          </Row>
        </Section>

        <Section title={formatMessage({ id: `sec.hwd.title` })} centered dark>
          <Row centered>
            {formatMessage({ id: `sec.hwd.content` })}
            <br />
            {formatMessage({ id: `sec.hwd.content1` })}
            <br />
            {formatMessage({ id: `sec.hwd.content2` })}
          </Row>

          <SectionBreak dark />

          <Row centered>
            <Column
              title={formatMessage({ id: `col.quality.title` })}
              image={ChecklistIcon}
              small
            >
              {formatMessage({ id: `col.quality.content` })}
            </Column>
            <Column
              title={formatMessage({ id: `col.delivery.title` })}
              image={ClockIcon}
              small
            >
              {formatMessage({ id: `col.delivery.content` })}
            </Column>
          </Row>
          <Row>
            <Column
              title={formatMessage({ id: `col.quote.title` })}
              image={QuoteIcon}
              small
            >
              {formatMessage({ id: `col.quote.content` })}
            </Column>
            <Column
              title={formatMessage({ id: `col.consulting.title` })}
              image={ShippingIcon}
              small
            >
              {formatMessage({ id: `col.consulting.content` })}
            </Column>
          </Row>
        </Section>
      </main>

      <SectionBreak dark />

      <Contacts
        title={formatMessage({ id: `sec.info.title` })}
        subtitle={formatMessage({ id: `sec.info.content` })}
        dark
      />
    </>
  );
};

IndexPage.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
};

export default injectIntl(IndexPage);
